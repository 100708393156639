import * as React from 'react';
import Layout from '../components/layout';

import './404.scss';

const NotFoundPage = () => {
	return <Layout pageClass="error-page"></Layout>;
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
